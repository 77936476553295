import React from 'react'
import styled from 'styled-components'
import {
  Dropdown,
  DefaultDropdown,
  Box,
  Flex,
} from '@makerdao/ui-components-core'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Link from '../components/link'
import { FormattedMessage as FM } from 'react-intl'
import PageWithTOC from '../components/pageWithTOC'
import getUrls from '../urls'

const Button = styled.div`
  display: flex;
  align-items: center;
  color: #a6a6a6;
  border: 1px solid #a6a6a6;
  border-radius: 33px;
  padding: 0.9rem 2rem;
  font-size: 1.6rem;
  cursor: pointer;
  letter-spacing: 0.3px;
  transition: all 0.1s ease;
`

const DownloadDropdownWrapper = styled(Flex)`
  :hover {
    ${Button} {
      border-color: #000;
      color: #000;
    }
  }
`

const LangDropdownItem = styled.div`
  cursor: pointer;
  line-height: 3.5rem;
  margin-right: 5rem;
  font-size: 1.6rem;
`
const LangDropdownLink = styled.a`
  color: #333;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.teal['500']};
  }
`

const WhitepaperMarkdownStyle = styled(Box)`
  p {
    line-height: 3rem;
    max-width: 920px;
  }
`

class Whitepaper extends React.Component {
  constructor(props) {
    super(props)

    this.languages = {
      English: 'en',
      Chinese: 'zh',
      French: 'fr',
      German: 'de',
      Italian: 'it',
      Japanese: 'ja',
      Korean: 'ko',
      Portugese: 'pt',
      Russian: 'ru',
      Spanish: 'es',
    }
  }

  render() {
    const locale = this.props.pageContext.locale
    const urls = getUrls(locale)
    return (
      <Layout
        locale={locale}
        hideFooter={true}
        meta={{ title: 'Sai Whitepaper' }}
        fixedMobileMenu={true}
      >
        <Box
          position="relative"
          width="100%"
          maxWidth="1200px"
          m={{ s: '10rem auto', l: '4.5rem auto' }}
          textAlign="center"
        >
          <Box width="100%" px={{ s: '20px', l: '50px' }} color="orange.800">
            <h3 style={{ color: 'inherit' }}>
              THIS IS THE OLD <u>SAI</u> WHITEPAPER, THE MAKER PROTOCOL NOW USES
              DAI, WHICH CAN BE FOUND <Link to={urls('Whitepaper')}>HERE</Link>.
            </h3>
          </Box>
        </Box>
        <Box mt={{ s: '-9rem', l: '-5.5rem' }} mb="5rem">
          <PageWithTOC headings={this.props.data.markdownRemark.headings}>
            <Flex
              justifyContent="space-between"
              width="100%"
              mb="3.8rem"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box pr="2rem">
                <h1
                  style={{
                    fontSize: '4.2rem',
                    lineHeight: '4.2rem',
                    fontWeight: 500,
                    letterSpacing: '0.04rem',
                    marginBottom: 0,
                  }}
                >
                  {this.props.data.markdownRemark.frontmatter.title}
                </h1>
              </Box>
              <DownloadDropdownWrapper>
                <Dropdown
                  trigger={
                    <Button>
                      <FM id="whitepaper.download-btn" />
                    </Button>
                  }
                  placement="bottom-end"
                  mt="1rem"
                >
                  <DefaultDropdown style={{ maxWidth: '146px' }}>
                    {Object.entries(this.languages).map(
                      ([langName, langCode]) => (
                        <LangDropdownItem key={langCode}>
                          <LangDropdownLink
                            href={`/whitepaper/Dai-Whitepaper-Dec17-${langCode}.pdf`}
                          >
                            {langName}
                          </LangDropdownLink>
                        </LangDropdownItem>
                      )
                    )}
                  </DefaultDropdown>
                </Dropdown>
              </DownloadDropdownWrapper>
            </Flex>
            <WhitepaperMarkdownStyle fontSize="1.7rem">
              <Markdown
                dangerouslySetInnerHTML={{
                  __html: this.props.data.markdownRemark.html,
                }}
              />
            </WhitepaperMarkdownStyle>
          </PageWithTOC>
        </Box>
      </Layout>
    )
  }
}

export default Whitepaper

export const query = graphql`
  query WhitepaperSai {
    markdownRemark(
      fileAbsolutePath: { glob: "**/whitepaper/whitepaper-sai.md" }
    ) {
      html
      frontmatter {
        title
      }
      headings {
        value
        depth
      }
    }
  }
`
